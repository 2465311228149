










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import FooterVue from "@/components/FooterVue.vue";
export default Vue.extend({
  name: "Preguntas",
  components: {
    Navbar,
    FooterVue,
  },
  data: () => ({
    error: "",
  }),
  props: {
    merchant: {},
  },
  methods: {},
});
